/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useOktaAuth } from "@okta/okta-react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { Accordion, PanelCard, Img } from "@sunwing/shared-components";
import { Row, Cell, Icon, Heading, Section } from "@sunwing/components";
import { Button } from "@sunwing/components/dist/Button";
import config from "../../app/config";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL, RCL as useTranslation } from "../../components/RCL";
import { useRouting, useApp } from "../../hooks";
import { Loader } from "../../components/Loader";
import { Select } from "antd";
import "antd/dist/antd.css";
import modalStyles from "./FeedbackModal.module.scss";
import starcardStyles from "../../components/StarCard/StarCard.module.scss";
import StarCardModal from "../../components/StarCard/StarCardModal";
import { Modal, Col, Image } from "antd";

let styles;
(async () => {
  if (config.isWestJetMode) {
    styles = await import("./MyAccountVwq.module.scss");
   
  } else {
    styles = await import("./MyAccount.module.scss");
  }
})();

const SelectOption = Select.Option;

const modal = classNames.bind(modalStyles);
const starclass = classNames.bind(starcardStyles);

const ToggleTemplate = ({ isActive, children }) => (
  <React.Fragment>
    {children}
    <Icon
      aria-hidden="true"
      name={`arrow-${isActive ? "up" : "down"}`}
      className={styles.toggleIcon}
    />
  </React.Fragment>
);

const MyAccount = () => {
  const { authState, oktaAuth, authService } = useOktaAuth();
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const { refreshUserInfo } = useApp();

  const pageContext = { basePath: "/", routingPaths: { login: "login" } };
  const [profileInitState, setProfileTempState] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);
  const [originalProfileInfo, setOriginalProfileInfo] = useState(null);
  const [loyaltyInitState, setLoyaltyTempState] = useState(null);
  const [loyaltyInfo, setLoyaltyInfo] = useState(null);
  const [starCardInfo,setStarCardInfo]=useState(null);
  const [provinceList, setProvinceList] = useState(null);
  const [regions, setRegions] = useState(null);
  const [agency, setdAgency] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState(null);
  const [globalId, setGlobalId] = useState();
  const [currentEmail, setCurrentEmail] = useState();
  const [hasProfileChanged, setProfileChangeState] = useState(false);
  const [newPhone, setNewPhone] = useState();
  const [validAgencyPhone, setValidAgencyPhone] = useState(true);
  const [loyaltyCardRegistered, setLoyaltyCardRegistered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAddress1Example, setShowAddress1Example] = useState(false);
  const [showRenewCard, setShowRenewCard] = useState(false);
  const [showRenewCardResult, setShowRenewCardResult] = useState(false);
  const [renewCardResult, setRenewCardResult] = useState(false);

  /////////////////
  /* Error modal */
  // Tracks whether a modal is displayed
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  // Determines if a modal displays within the FocusTrap component (has interactive elements)
  const [isFocusTrapActive, setFocusTrapActive] = useState(false);
  // Tracks which modal is active at any givent time
  const [whichFeedbackModal, setFeedbackModal] = useState(null);
  // Tracks errors messages from fetch calls
  const [modalErrorMessage, setErrorMessage] = useState(null);
  // Tracks the save process to and from AccountProfile component
  const [savePanel, setSavePanel] = useState(null);
  // Determines the modal action behavior when a button is triggered
  const [saveAction, setSaveAction] = useState(null);
  ////////////////
  const [loaded, setLoaded] = useState(false);
  const [loyaltyLoaded, setLoyaltyLoaded] = useState(false);

  const { basePath, routingPaths } = pageContext;

  // Tracks if profile password has changed and not saved
  const [hasPasswordChanged, setPasswordChanged] = useState(false);

  const getRegions = () => {
    fetch(
      `${config.resourceServer.agentApiUrl}/opn/content/region?lang=${locale}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setRegions(data);
      });
  };

  const getAgency = (agencyNum) => {
    fetch(`${config.resourceServer.agentApiUrl}/opn/agency/${agencyNum}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setdAgency(data);
        if (agency && agency.Name !== null) {
          setValidAgencyPhone(true);
        } else{
          //To handle valid phone number copy paste
          //if it's wrong phone it will throw error not null
          setValidAgencyPhone(true);  
        }
      })
      .catch((err) => {
        // Send failure feedback to the user        
        setValidAgencyPhone(false);
        displayFeedbackModal("ERR_AgencyPhoneNotValid", err);
      });
  };

  const getProfileInfo = (token) => {
    fetch(`${config.resourceServer.agentApiUrl}/account/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        const initialUpdate = {
          ...data,
          preferredLanguage: data.contactPreferences.languageCode,
          preferredRegion:
            data.contactPreferences.departureProvinces !== undefined
              ? data.contactPreferences
              : { departureProvinces: [{ code: "CA", name: "Canada" }] },
        };
        setProfileInfo(initialUpdate);
        setOriginalProfileInfo(initialUpdate);
        setProfileTempState(initialUpdate);
        setLoaded(true);
      });
  };

  const getLoyaltyInfo = (token) => {
    fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((res) => {
        const data = res.starAgentInfo;
        const provinceData = res.provinces;
        const starCard=res.starCard;
        setLoyaltyInfo(data);
        setStarCardInfo(starCard);
        setLoyaltyCardRegistered(res.isStarRegistered);
        setLoyaltyTempState(data);
        setProvinceList(provinceData);
        setLoyaltyLoaded(true);
      });
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      getRegions();
      getProfileInfo(accessToken);
      getLoyaltyInfo(accessToken);

      const params = new URL(window.location.href).searchParams;
      const section = params.get('section');
      if(section === 'star'){
        setShowRenewCard(true);
        setActivePanel("starloyalty-program");
      }
    }
  }, [authState, oktaAuth, locale]);

  const displayFeedbackModal = useCallback((modalType, modalError) => {
    // Purpose: Sets up the display properties to render the modal types
    if (modalError) {
      setErrorMessage(modalError);
    }

    if (modalType) {
      setIsFeedbackModalOpen(true);
      setFeedbackModal(modalType);
    } else {
      // Resets modal states to disable and hide presentation
      setIsFeedbackModalOpen(false);
      setFeedbackModal();
    }
  }, []);

  const closeFeedbackModal = () => {
    // Purpose: Closes the feedback modal, typically attached to a close button in the modal
    displayFeedbackModal(false);
  };

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      throw err;
    }
  };

  const handleShowRegistrationModal = async () => {
    //code for popup model
    setIsOpen(!isOpen);
  };

  const dictionary = {
    "okta-profile-panel-cancel": useTranslation({
      searchKey: "okta-profile-panel-cancel",
    }),
    "okta-profile-panel-save": useTranslation({
      searchKey: "okta-profile-panel-save",
    }),
    "okta-widget-chars": useTranslation({ searchKey: "okta-widget-chars" }),
    "okta-toggle-visibility": useTranslation({
      searchKey: "okta-profile-panel-toggle-visibility",
    }),
    "okta-profile-feedback-account-profile": useTranslation({
      searchKey: "okta-profile-feedback-account-profile",
    }),
    "modal-close": useTranslation({ searchKey: "modal-close" }),
    "okta-profile-feedback-save-success": useTranslation({
      searchKey: "okta-profile-feedback-save-success",
    }),
    "okta-profile-feedback-error-an-issue-has-been-encountered": useTranslation(
      {
        searchKey: "okta-profile-feedback-error-an-issue-has-been-encountered",
      }
    ),
    "okta-profile-feedback-save-these-changes": useTranslation({
      searchKey: "okta-profile-feedback-save-these-changes",
    }),
    "okta-profile-feedback-error-wrong-old-password": useTranslation({
      searchKey: "okta-profile-feedback-error-wrong-old-password",
    }),
    "okta-profile-feedback-error-password-too-recent": useTranslation({
      searchKey: "okta-profile-feedback-error-password-too-recent",
    }),
    "okta-profile-feedback-dont-save": useTranslation({
      searchKey: "okta-profile-feedback-dont-save",
    }),
    "okta-profile-feedback-proceed": useTranslation({
      searchKey: "okta-profile-feedback-proceed",
    }),
    "okta-profile-save-success-relogin": useTranslation({
      searchKey: "okta-profile-save-success-relogin",
    }),
    "okta-profile-panel-logout": useTranslation({
      searchKey: "okta-profile-panel-logout",
    }),
    ERR_RequestNotCompleted: useTranslation({
      searchKey: "ERR_RequestNotCompleted",
    }),
    ERR_AgencyPhoneNotValid: useTranslation({
      searchKey: "ERR_AgencyPhoneNotValid",
    }),
    save: useTranslation({
      searchKey: "save",
    }),
    cancel: useTranslation({
      searchKey: "cancel",
    }),
    "privacy-policy-link": useTranslation({
      searchKey: "PrivacyPolicy",
    }),
    "privacy-policy-link-text": useTranslation({
      searchKey: "PrivacyPolicylinktext",
    }),
    "contact-us-link": useTranslation({
      searchKey: "Contactuslink",
    }),
    "contact-us-link-text": useTranslation({
      searchKey: "Contactuslinktext",
    }),
    "star-proxy-image": useTranslation({
      searchKey: "Starproxyimage",
    }),
    registerToStar: useTranslation({
      searchKey: "registerToStar",
    }),
    STAR_whatprogram: useTranslation({
      searchKey: "STAR_whatprogram",
    }),
    processing:useTranslation({
      searchKey: "processing",
    }),
    "confirm-renewal": useTranslation({
      searchKey: "confirm-renewal",
    }),
    "cancel-renewal":useTranslation({
      searchKey: "cancel-renewal",
    }),
  };

  /**
	|--------------------------------------------------
	| Handle Feedback Modal
	|--------------------------------------------------
	*/
  const [modalHeaderRCLKey, setModalHeaderRCLKey] = useState();
  const [modalBodyRCLKey, setModalBodyRCLKey] = useState();
  const [modalShowLoader, setModalShowLoader] = useState();
  // TODO : Figure out how to make focus-trap work without having any elements focusable
  const [modalClosable, setModalClosable] = useState();

  useEffect(() => {
    if (whichFeedbackModal === "saveProfile") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey();
      setModalShowLoader(true);
      setModalClosable(false);
      setFocusTrapActive(false);
    } else if (whichFeedbackModal === "saveProfileSuccess") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey("okta-profile-feedback-save-success");
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "saveProfileError") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey(
        "okta-profile-feedback-error-an-issue-has-been-encountered"
      );
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "saveEmail") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey();
      setModalShowLoader(true);
      setModalClosable(false);
      setFocusTrapActive(false);
    } else if (whichFeedbackModal === "logOutModal") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey("okta-profile-save-success-relogin");
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "saveEmailError") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey("ERR_RequestNotCompleted");
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "ERR_AgencyPhoneNotValid") {
      setModalHeaderRCLKey("ERR_AgencyPhoneNotValid");
      setModalBodyRCLKey();
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "savePasswordSuccess") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      setModalBodyRCLKey("okta-profile-feedback-save-success");
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "savePasswordError") {
      setModalHeaderRCLKey("okta-profile-feedback-account-profile");
      if (modalErrorMessage?.indexOf("Old Password is not correct") >= 0) {
        setModalBodyRCLKey("okta-profile-feedback-error-wrong-old-password");
      } else if (
        modalErrorMessage?.indexOf("Password has been used too recently") >= 0
      ) {
        setModalBodyRCLKey("okta-profile-feedback-error-password-too-recent");
      } else {
        // Fallback error message for everything else
        setModalBodyRCLKey(
          "okta-profile-feedback-error-an-issue-has-been-encountered"
        );
      }
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else if (whichFeedbackModal === "logOutModal") {
      setModalHeaderRCLKey("okta-profile-feedback-save-these-changes");
      setModalBodyRCLKey("okta-profile-feedback-save-success");
      setModalShowLoader(false);
      setModalClosable(true);
      setFocusTrapActive(true);
    } else {
      setFocusTrapActive(false);
    }
  }, [whichFeedbackModal, modalErrorMessage]);

  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  // eslint-disable-next-line no-unused-vars
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();

  // Profile fields management
  const [isInitialized, setProfileInit] = useState(false);

  // Loyalty fields management
  const [isLoyaltyInitialized, setLoyaltyInit] = useState(false);

  // Validation tracking and true data source
  const [accordionConfig, setConfigState] = useState({
    "profile-info": {
      fields: {
        firstName: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "name",
        },
        lastName: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "name",
        },
        email: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "email",
        },
        preferredLanguage: {
          value: "",
          isRequired: false,
          isValid: false,
          hasChanged: false,
          validationType: "radio",
        },
        agencyName: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "name",
        },
        agencyNumber: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "name",
        },
        preferredRegion: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "multipleSelect",
        },
      },
      isValid: false,
      hasChanged: false,
    },
    "loyalty-program": {
      fields: {
        cPartnerCard: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
      },
      isValid: true,
      hasChanged: false,
    },
    "starloyalty-program": {
      fields: {
        cPaidMobile: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
        cContactPhone: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
        cFax: {
          value: " ",
          isRequired: false,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
        cAddress1: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
        cCity: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "name",
        },
        cProvince: {
          value: "",
          isRequired: true,
          isValid: false,
          hasChanged: false,
          validationType: "select",
        },
        cPostalCode: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "postal",
        },
      },
      isValid: true,
      hasChanged: false,
    },
    "email-password": {
      fields: {
        email: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "email",
        },
      },
      isValid: true,
      hasChanged: false,
    },
    "change-login-email": {
      fields: {
        currentEmail: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "email",
        },
        changeEmail: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "email",
        },
        confirmChangeEmail: {
          value: "",
          isRequired: true,
          isValid: true,
          hasChanged: false,
          validationType: "email",
        },
      },
      isValid: true,
      hasChanged: false,
    },
  });

  // Active accordion panel
  const [activePanel, setActivePanel] = useState();

  // Password fields management
  const [isPasswordValid, setValidPassword] = useState(false);
  const newPasswordField = document.getElementById("ap-newPassword");
  const newPasswordConfirmField = document.getElementById(
    "ap-newPasswordConfirm"
  );
  const oldPasswordField = document.getElementById("ap-oldPassword");

  // Okta password validation rules
  const [getRules, setRules] = useState(null); // API JSON

  // Okta password complexity settings
  const [getCharLength, setCharLength] = useState(10); //natural number
  const [getNumberChars, setNumberChars] = useState(null); // regex
  const [getSymbolChars, setSymbolChars] = useState(null); // regex
  const [getLowerCaseChars, setLowerCaseChars] = useState(null); // regex
  const [getUpperCaseChars, setUpperCaseChars] = useState(null); // regex
  const [isUserNameChecked, setUserNameChecked] = useState(null); // regex

  const validateField = (fieldName, fieldValue, type, required) => {
    // Regex tests to validate each field type
    if (type === "name") {
      const characterLimit = 50;
      if (required) {
        if (fieldValue.trim().length < 1) {
          return "required";
        }

        if (fieldValue.trim().length > characterLimit) {
          return false;
        }

        return true;
      }

      if (!required) {
        if (fieldValue.trim().length < 1) {
          return true;
        }
        if (fieldValue.trim().length > characterLimit) {
          return false;
        }

        return true;
      }
    } else if (type === "phone") {
      const regexPattern = /[\s|(|)|-]/gi;
      const cleanedInput = fieldValue.replace(regexPattern, "");

      if (required) {
        if (cleanedInput.length < 1) {
          return "required";
        }

        return /^[0-9]{10}$/.test(cleanedInput);
      }

      if (!required) {
        if (cleanedInput.length < 1) {
          return true;
        }

        return /^[0-9]{10}$/.test(cleanedInput);
      }
    } else if (type === "select") {
      if (required) {
        if (fieldValue.length < 1) {
          return "required";
        }

        return true;
      }

      if (!required) {
        if (fieldValue.length < 1) {
          return true;
        }

        return true;
      }
    } else if (type === "checkbox") {
      if (required) {
        if (
          document.getElementsByName(fieldName)[0] &&
          document.getElementsByName(fieldName)[0].checked
        ) {
          return true;
        }
        return "required";
      }
      return true;
    } else if (type === "radio") {
      if (required) {
        if (fieldValue.length > 0) {
          return true;
        }
        return "required";
      }
      return true;
    } else if (type === "date") {
      if (required) {
        if (fieldValue.trim().length < 1) {
          return "required";
        }
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fieldValue);
      }

      if (!required) {
        if (fieldValue.trim().length < 1) {
          return true;
        }
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fieldValue);
      }
    } else if (type === "email") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@'“‘”’]+(\.[^<>()[\]\\.,;:\s@'“‘”’]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (required === "required") {
        if (fieldValue.length < 1) {
          return "required";
        }
        return emailRegex.test(fieldValue);
      }
      if (fieldValue.length < 1) {
        return true;
      }

      return emailRegex.test(fieldValue);
    } else if (type === "multipleSelect") {
      if (required) {
        if (fieldValue.length < 1) {
          return "required";
        }
        return true;
      }

      if (!required) {
        if (fieldValue.length < 1) {
          return true;
        }
        return true;
      }
    } else if (type === "postal") {
      const postalRegex =
        /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ].?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

      if (required === "required") {
        if (fieldValue.length < 1) {
          return "required";
        }
      }
      if (fieldValue.length < 1) {
        return true;
      }
      if (fieldValue.replace(/\s/g, "").length !== 6) {
        return false;
      }
      return postalRegex.test(fieldValue);
    } 

    return false;
  };

  const updateConfigData = (userProfileData, loyalyData, reset) => {
    /* Purpose: analyzes accordionConfig object and updates data and validation states on initial page load */
    const tempConfig = { ...accordionConfig };
    const panelIdList = Object.keys(tempConfig);

    panelIdList.forEach((panelId) => {
      const panelKeyList = Object.keys(tempConfig[panelId].fields);

      let validRequiredTotal = 0;
      let validRequiredCounter = 0;
      let changedFlag = false;

      panelKeyList.forEach((key) => {
        const field = tempConfig[panelId].fields[key];
        if (
          panelId === "starloyalty-program" ||
          panelId === "loyalty-program"
        ) {
          field.hasChanged = reset
            ? false
            : loyaltyInitState[key] !== loyalyData[key];
          field.value = loyalyData[key] ? loyalyData[key] : "";

          if (loyalyData[key]) {
            field.isValid = validateField(
              key,
              accordionConfig[panelId].fields[key].value,
              accordionConfig[panelId].fields[key].validationType,
              accordionConfig[panelId].fields[key].isRequired
            );
          }
        } else {
          field.hasChanged = reset
            ? false
            : profileInitState[key] !== userProfileData[key];
          field.value = userProfileData[key] ? userProfileData[key] : "";

          if (userProfileData[key]) {
            field.isValid = validateField(
              key,
              accordionConfig[panelId].fields[key].value,
              accordionConfig[panelId].fields[key].validationType,
              accordionConfig[panelId].fields[key].isRequired
            );
          }
        }

        if (field.isRequired) {
          validRequiredTotal += 1;
        }

        if (field.isValid === true) {
          if (field.isRequired) {
            validRequiredCounter += 1;
          }

          if (field.hasChanged) {
            changedFlag = true;
          }
        }
      });

      tempConfig[panelId].hasChanged = reset ? false : changedFlag;

      if (validRequiredCounter === validRequiredTotal) {
        tempConfig[panelId].isValid = true;
      } else {
        tempConfig[panelId].isValid = false;
      }
    });

    setConfigState(tempConfig);
  };

  const updateConfigFieldData = (formPanel, formField, fieldFieldValue) => {
    /* Purpose: Individual fields are analyzed and updated in accordionConfig */
    const tempConfig = { ...accordionConfig };   
    if (formPanel && formField && typeof fieldFieldValue !== "undefined") {
      const field = tempConfig[formPanel].fields[formField];
      if (
        formPanel === "starloyalty-program" ||
        formPanel === "loyalty-program"
      ) {
        field.hasChanged = loyaltyInitState[formField] !== fieldFieldValue;
      } else {
        field.hasChanged = profileInitState[formField] !== fieldFieldValue;
      }

      field.value = fieldFieldValue;

      field.isValid = validateField(
        formField,
        fieldFieldValue,
        accordionConfig[formPanel].fields[formField].validationType,
        accordionConfig[formPanel].fields[formField].isRequired
      );     
    }

    // Check all fields are valid
    const panelKeyList = Object.keys(tempConfig[formPanel].fields);
    let validRequiredTotal = 0;
    let validRequiredCounter = 0;
    let changedFlag = false;

    panelKeyList.forEach((key) => {
      const field = tempConfig[formPanel].fields[key];

      if (field.isRequired) {
        validRequiredTotal += 1;
      }

      if (field.isValid === true) {
        if (field.isRequired) {
          validRequiredCounter += 1;
        }
      }
      if (field.hasChanged) {
        changedFlag = true;
      }
    });

    tempConfig[formPanel].hasChanged = changedFlag;

    if (validRequiredCounter === validRequiredTotal) {
      tempConfig[formPanel].isValid = true;
    } else {
      tempConfig[formPanel].isValid = false;
    }

    setProfileChangeState(changedFlag);

    setConfigState(tempConfig);
  };

  const allowNumbers = (event, length) => {
    // Limits the keyboard entry for mobile phone field (restricts to numbers)
    const charCode = event.keyCode.which ? event.keyCode.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || length >= 10) {
      event.preventDefault();
    }
  };

  const handleMultipleSelect = (value) => {
    updateConfigFieldData("profile-info", "preferredRegion", value);

    let updatedRegion = value;
    if (value.length > 1 && value[value.length - 1].key === "CA") {
      updatedRegion = value.filter((x) => x.key === "CA");
    }

    if (value.length > 1 && value[value.length - 1].key !== "CA") {
      updatedRegion = value.filter((x) => x.key !== "CA");
    }

    setSelectedRegions(updatedRegion);
  };

  const handleChange = (event) => {
    /* Purpose: Updates the profile temp state per field */
    const { name: field, value } = event.target;

    updateConfigFieldData(activePanel, field, value);

    let updatedProfile = {
      ...profileInfo,
      [field]: value,
    };

    // Manually update locale based on language preference
    if (field === "preferredLanguage") {
      updatedProfile.contactPreferences.languageCode =
        value === "fr" ? value : `${value}_CA`;
    } else if (field === "preferredRegion") {
      let selectedPreferredProvince = selectedRegions.map((item) => {
        return Object.assign({}, [], { code: item.key, name: item.label });
      });
      updatedProfile = Object.assign(updatedProfile, {
        contactPreferences: {
          ...updatedProfile.contactPreferences,
          departureProvinces: selectedPreferredProvince,
        },
      });
    }

    setProfileInfo(updatedProfile);
  };

  const handleAgencyChange = (event) => {
    /* Purpose: Updates the profile temp state per field */
    const { name: field, value } = event.target;
    setNewPhone(event.target.value);
    setValidAgencyPhone(validatePhoneFormat());
    updateConfigFieldData(activePanel, field, value);
    if (value.length >= 10) {
      getAgency(value);
    }

    const updatedProfile = {
      ...profileInfo,
      [field]: value,
    };
    setProfileInfo(updatedProfile);

    if (agency && agency.Name !== null) {
      const updatedProfileforAgency = {
        ...profileInfo,
        [field]: value,
        agencyName: agency.name,
      };
      setProfileInfo(updatedProfileforAgency);
    }
  };

  const getConfigPanelData = (formPanel) => {
    /* Purpose: Retrieve the form panel data that has changed and pass it back to handleUpdateProfile method for saving */
    const tempConfig = { ...accordionConfig };
    let changedObject = { ...profileInitState };

    // Get all panel specific fields
    const panelKeyList = Object.keys(tempConfig[formPanel].fields);

    panelKeyList.forEach((key) => {
      const field = tempConfig[formPanel].fields[key];

      if (field.hasChanged) {
        changedObject = Object.assign(changedObject, { [key]: field.value });

        if (key === "preferredLanguage") {
          changedObject = Object.assign(changedObject, {
            contactPreferences: {
              ...profileInitState.contactPreferences,
              languageCode:
                field.value === "fr" ? field.value : `${field.value}_CA`,
            },
          });
        }

        if (key === "preferredRegion") {
          let selectedPreferredProvince = selectedRegions.map((item) => {
            return Object.assign({}, [], { code: item.key, name: item.label });
          });
          changedObject = Object.assign(changedObject, {
            contactPreferences: {
              ...changedObject.contactPreferences,
              departureProvinces: selectedPreferredProvince,
            },
          });
        }
      }
    });

    return changedObject;
  };

  const handleUpdateProfile = (event) => {
    /* Purpose: Determine any profile data changes and save to Okta profile */
    if (event) {
      event.preventDefault();
    }
    const changedProfileData = getConfigPanelData(activePanel);    
    setSubmitting(true);

    // Send feedback to user
    displayFeedbackModal("saveProfile");
    fetch(`${config.resourceServer.agentApiUrl}/account/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      method: "PUT",
      body: JSON.stringify(changedProfileData),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 200) {
            return res.json().then((json) => {
              if (json.errorCode) {
                // Catch Okta object format
                throw new Error(`${json.errorCode} ${json.errorSummary}`);
              } else if (typeof json === "string") {
                // Otherwise assume string
                throw new Error(json);
              }
            });
          }
        }
        return res.json();
      })
      .then((data) => {
        const updatedAdditionalData = {
          ...data,
          preferredLanguage: data.contactPreferences.languageCode,
          preferredRegion:
            data.contactPreferences.departureProvinces !== undefined
              ? data.contactPreferences
              : { departureProvinces: [{ code: "CA", name: "Canada" }] },
        };

        setProfileInfo(updatedAdditionalData);
        setProfileTempState(updatedAdditionalData);
        setOriginalProfileInfo(updatedAdditionalData);
        updateConfigData(updatedAdditionalData, loyaltyInfo, true);
        refreshUserInfo({
          name: `${data.firstName.trim()} ${data.lastName.trim()}`,
          given_name: data.firstName.trim(),
          family_name: data.lastName.trim()
        });

        // Send success feedback to the user
        displayFeedbackModal("saveProfileSuccess");
        setProfileChangeState(false);
        setSavePanel("save-success");

        // Remove when using the user/me endpoint
        return new Promise((resolve) => {
          resolve();
        });
      })
      .catch((err) => {
        // Send failure feedback to the user
        displayFeedbackModal("saveProfileError", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const resetProfileState = () => {
    // Clear entered data and reset to initial profile
    if (profileInitState !== null) {
      setProfileInfo(profileInitState);
      const selRegion =
        profileInfo.contactPreferences?.departureProvinces !== undefined
          ? profileInfo.contactPreferences?.departureProvinces.map((item) => {
              return Object.assign({}, item, {
                key: item.code,
                label: item.name,
              });
            })
          : [{ key: "CA", label: "Canada" }];
      setSelectedRegions(selRegion);
      setTimeout(() => {
        updateConfigData(profileInitState, loyaltyInitState, true);
        setProfileChangeState(false);
      }, 100);
    }
  };

  const togglePassword = (event, element) => {
    /* Purpose: Handle the toggle icon behavior for displaying the password for 30 seconds */
    const useIntervalState = true;
    let toggleState =
      event.currentTarget.getAttribute("data-toggle") === "true";
    toggleState = !toggleState;
    event.currentTarget.setAttribute("data-toggle", toggleState);

    const counterLimit = 30;
    let counter = 0;
    const thisTarget = event.currentTarget;

    if (toggleState) {
      element.setAttribute("type", "text");

      if (useIntervalState) {
        // Set up interval to automatically hide password
        const togglePasswordInterval = window.setInterval(
          () => {
            if (counter >= counterLimit || !toggleState) {
              window.clearInterval(togglePasswordInterval);

              thisTarget.setAttribute("data-toggle", false);
              element.setAttribute("type", "password");
              element.removeAttribute("data-toggleCounter");
            }

            counter += 1;
          },
          1000,
          thisTarget
        );
        element.setAttribute("data-toggleCounter", togglePasswordInterval);
      }
    } else {
      const toggleCounter = element.getAttribute("data-toggleCounter");
      if (toggleCounter && useIntervalState) {
        element.removeAttribute("data-toggleCounter");
        window.clearInterval(parseInt(toggleCounter, 10));
      }
      element.setAttribute("type", "password");
    }
  };

  const displayValidationMessage = (panelId, fieldName, type) => {
    const isValid = validateField(
      fieldName,
      accordionConfig[panelId].fields[fieldName].value,
      type,
      accordionConfig[panelId].fields[fieldName].isRequired
    );

    let errorMessage = null;

    switch (isValid) {
      case "required":
        errorMessage = (
          <div className={styles.validationErrorMessage}>
            <RCL searchKey="okta-profile-validation-this-field-is-required" />
          </div>
        );
        break;
      case false:
        errorMessage = (
          <div className={styles.validationErrorMessage}>
            <RCL searchKey="oktta-profile-validation-this-field-is-invalid" />
          </div>
        );
        break;

      default:
        errorMessage = null;
    }
    return errorMessage;
  };

  const validateChars = () => {
    const charsErrorMin = getCharLength;
    const charsErrorState = newPasswordField.value.length >= charsErrorMin;
    return charsErrorState;
  };

  const validateMinNumber = () => {
    const numberErrorState = new RegExp(getNumberChars, "g").test(
      newPasswordField.value
    );
    return numberErrorState;
  };

  const validateLowercase = () => {
    const lowercaseErrorState = new RegExp(getLowerCaseChars, "g").test(
      newPasswordField.value
    );
    return lowercaseErrorState;
  };

  const validateUppercase = () => {
    const uppercaseErrorState = new RegExp(getUpperCaseChars, "g").test(
      newPasswordField.value
    );
    return uppercaseErrorState;
  };

  const validateSymbolChars = () => {
    const specialCharsErrorState = new RegExp(getSymbolChars, "g").test(
      newPasswordField.value
    );
    return specialCharsErrorState;
  };

  const validateMatchOldPassword = () => {
    const samePasswordState = newPasswordField.value !== oldPasswordField.value;
    return samePasswordState;
  };

  const validateSamePassword = () => {
    const samePasswordState =
      newPasswordField.value === newPasswordConfirmField.value;
    return samePasswordState;
  };

  const validateUniqueInput = () => {
    const emailGroupsRegex1 =
      /^([^<>()[\]\\.,;:\s@'“‘”’]+(\.[^<>()[\]\\.,;:\s@'“‘”’]+)*)/;
    const emailGroupsRegex2 =
      /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const usernameInput = profileInfo.emailAddress.email;

    const emailGroupsErrorArray1 = usernameInput
      .match(emailGroupsRegex1)[0]
      .split(/[.|-|+|_]/);
    const emailGroupsErrorArray2 = usernameInput
      .match(emailGroupsRegex2)[0]
      .split(/[.]/)[0];
    let emailIdentifierState = false;

    let isMatch = false;

    // Test email username array
    emailGroupsErrorArray1.forEach((value) => {
      emailIdentifierState = new RegExp(value, "gi").test(
        newPasswordField.value
      );
      if (emailIdentifierState) {
        isMatch = emailIdentifierState;
      }
    });

    emailIdentifierState = isMatch;

    // Test email domain
    emailIdentifierState = !emailIdentifierState
      ? new RegExp(emailGroupsErrorArray2, "gi").test(newPasswordField.value)
      : emailIdentifierState;

    return !emailIdentifierState && newPasswordField.value.trim() !== "";
  };

  const validateFirstNameMatch = () => {
    // First name validation
    let firstNameErrorState = false;
    const panelObj = accordionConfig["profile-info"].fields;

    firstNameErrorState = !firstNameErrorState
      ? !new RegExp(panelObj.firstName.value, "gi").test(newPasswordField.value)
      : firstNameErrorState;

    return firstNameErrorState && newPasswordField.value.length > 0;
  };

  const validateLastNameMatch = () => {
    // Last name validation
    let lastNameErrorState = false;
    const panelObj = accordionConfig["profile-info"].fields;

    lastNameErrorState = !lastNameErrorState
      ? !new RegExp(panelObj.lastName.value, "gi").test(newPasswordField.value)
      : lastNameErrorState;

    return lastNameErrorState && newPasswordField.value.length > 0;
  };

  const handlePasswordValidation = () => {
    if (newPasswordField && newPasswordConfirmField && oldPasswordField) {
      if (
        // Okta driven validations
        validateChars() &&
        // Conditionally validated based on API availability
        (getNumberChars !== null ? validateMinNumber() : true) &&
        (getLowerCaseChars !== null ? validateLowercase() : true) &&
        (getUpperCaseChars !== null ? validateUppercase() : true) &&
        (getSymbolChars !== null ? validateSymbolChars() : true) &&
        // Custom validations
        (isUserNameChecked !== null ? validateUniqueInput() : true) &&
        (isUserNameChecked !== null ? validateFirstNameMatch() : true) &&
        (isUserNameChecked !== null ? validateLastNameMatch() : true) &&
        validateMatchOldPassword() &&
        validateSamePassword() &&
        oldPasswordField?.value.length > 0
      ) {
        setValidPassword(true);
        setPasswordChanged(true);
      } else {
        setValidPassword(false);
        setPasswordChanged(false);
      }
    }
  };

  const handlePasswordReset = () => {
    setValidPassword(false);
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
    newPasswordField.value = "";
    newPasswordConfirmField.value = "";
    oldPasswordField.value = "";
    setPasswordChanged(false);
    setProfileChangeState(false);
  };

  const handleChangePassword = (event) => {
    /* Purpose: Handle the updating of Okta password */
    if (event) {
      event.preventDefault();
    }

    setSubmitting(true);

    // Send feedback to user
    displayFeedbackModal("saveProfile");

    return (
      fetch(
        `${
          config.oidc.issuer.split("/oauth2")[0]
        }/api/v1/users/me/credentials/change_password`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          method: "POST",
          body: JSON.stringify({
            oldPassword: { value: oldPassword },
            newPassword: { value: newPassword },
          }),
        }
      )
        .then((res) => {
          if (!res.ok) {
            if (res.status !== 200) {
              return res.json().then((json) => {
                if (json.errorCode) {
                  // Catch Okta object format
                  if (json.errorCauses.length > 0) {
                    throw new Error(
                      `${json.errorCode}|${json.errorCauses[0].errorSummary}`
                    );
                  } else {
                    throw new Error(`${json.errorCode}|${json.errorSummary}`);
                  }
                } else if (typeof json === "string") {
                  // Otherwise assume string
                  throw new Error(json);
                }
              });
            }
          }
          return res.json();
        })
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          // Send success feedback to the user
          displayFeedbackModal("savePasswordSuccess");
          setProfileChangeState(false);
          setSavePanel("save-success");
          handlePasswordReset();
        })
        .catch((err) => {
          // Send failure feedback to the user
          displayFeedbackModal("savePasswordError", err.toString());
        })
        .finally(() => {
          setSubmitting(false);
        })
    );
  };

  const loadPasswordValidationRules = () => {
    fetch(
      `${config.oidc.issuer.split("/oauth2")[0]}/api/v1/registration/form`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res.error;
        }
        return res.json();
      })
      .then((res) => {
        const passwordRules = res.profileSchema.properties.password.allOf;
        setRules(passwordRules);
        // Setup password rules
        passwordRules.forEach((rule) => {
          if (typeof rule.minLength === "number") {
            // Update number of minimum characters
            setCharLength(rule.minLength);
          } else if (rule.format) {
            if (rule.format === "/[\\d]+/") {
              setNumberChars(rule.format.substring(1, rule.format.length - 1));
            } else if (rule.format === "/[a-z]+/") {
              setLowerCaseChars(
                rule.format.substring(1, rule.format.length - 1)
              );
            } else if (rule.format === "/[A-Z]+/") {
              setUpperCaseChars(rule.format.replace(/\//g, ""));
            } else if (
              rule.format === "/[-!$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\\\\\/@#]+/"
            ) {
              setSymbolChars(rule.format.substring(1, rule.format.length - 1));
            } else if (rule.format === "/^[#/userName]/") {
              setUserNameChecked(rule.format);
            }
          }
        });
      })
      .catch((err) => {
        console.error("Failed to load validations: ", err);
        setRules(false);
      });
  };

  /******************Loyalty change****************************/
  const resetRIUInfoState = () => {
    // Clear entered data and reset to initial profile
    if (loyaltyInitState !== null) {
      if (loyaltyInitState.cPartnerCard === undefined) {
        const partnerCardField = document.getElementById("ap-cPartnerCard");
        partnerCardField.value = "";
      }
      setLoyaltyInfo(loyaltyInitState);

      setTimeout(() => {
        updateConfigData(profileInitState, loyaltyInitState, true);
        setProfileChangeState(false);
      }, 100);
    }
  };

  const handleUpdateRiuInfo = (event) => {
    /* Purpose: Determine any riu data changes and save to star db*/
    if (event) {
      event.preventDefault();
    }
    const updatedStarInfoData = {
      Agent: {
        ...loyaltyInfo,
      },
    };
    setSubmitting(true);
    // Send feedback to user
    displayFeedbackModal("saveProfile");

    fetch(`${config.resourceServer.agentApiUrl}/loyalty?type=riu`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify(updatedStarInfoData),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 200) {
            return res.json().then((json) => {
              if (json.errorCode) {
                // Catch Okta object format
                throw new Error(`${json.errorCode} ${json.errorSummary}`);
              } else if (typeof json === "string") {
                // Otherwise assume string
                throw new Error(json);
              }
            });
          }
        }
        return res.json();
      })
      .then((data) => {
        const updatedAdditionalData = {
          ...data,
          preferredLanguage: data.contactPreferences.languageCode,
          preferredRegion:
            data.contactPreferences.departureProvinces !== undefined
              ? data.contactPreferences
              : { departureProvinces: [{ code: "CA", name: "Canada" }] },
        };
        setProfileInfo(updatedAdditionalData);
        setProfileTempState(updatedAdditionalData);
        updateConfigData(updatedAdditionalData, loyaltyInfo, true);

        // Send success feedback to the user
        displayFeedbackModal("saveProfileSuccess");
        setProfileChangeState(false);
        setSavePanel("save-success");

        return new Promise((resolve) => {
          resolve();
        });
      })
      .catch((err) => {
        // Send failure feedback to the user
        displayFeedbackModal("saveProfileError", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  /****************************************************************/

  /******************Star Loyalty change****************************/
  const resetStarInfoState = () => {
    // Clear entered data and reset to initial profile
    if (loyaltyInitState !== null) {
      setLoyaltyInfo(loyaltyInitState);
      setTimeout(() => {
        updateConfigData(profileInitState, loyaltyInitState, true);
        setProfileChangeState(false);
      }, 100);
    }
  };

  const handleStarChange = (event) => {
    /* Purpose: Updates the profile temp state per field */
    const { name: field, value } = event.target;
    updateConfigFieldData(activePanel, field, value);

    const updatedLoyaltyInfo = {
      ...loyaltyInfo,
      [field]: value,
    };

    setLoyaltyInfo(updatedLoyaltyInfo);    

    if(field === 'cAddress1'){
      setShowAddress1Example(false);
    }
  };

  const handleStarAddress1Focus = (event) => {
    setShowAddress1Example(true);
  }

  const handleUpdateStarInfo = (event) => {
    /* Purpose: Determine any profile data changes and save to Okta profile */
    if (event) {
      event.preventDefault();
    }
    const updatedStarInfoData = {
      Agent: {
        ...loyaltyInfo,
      },
    };

    setSubmitting(true);

    // Send feedback to user
    displayFeedbackModal("saveProfile");

    fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify(updatedStarInfoData),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 200) {
            return res.json().then((json) => {
              if (json.errorCode) {
                // Catch Okta object format
                throw new Error(`${json.errorCode} ${json.errorSummary}`);
              } else if (typeof json === "string") {
                // Otherwise assume string
                throw new Error(json);
              }
            });
          }
        }
        return res.json();
      })
      .then((data) => {
        const updatedAdditionalData = {
          ...data,
          preferredLanguage: data.contactPreferences.languageCode,
          preferredRegion:
            data.contactPreferences.departureProvinces !== undefined
              ? data.contactPreferences
              : { departureProvinces: [{ code: "CA", name: "Canada" }] },
        };
        setProfileInfo(updatedAdditionalData);
        setProfileTempState(updatedAdditionalData);
        setLoyaltyTempState(loyaltyInfo);
        updateConfigData(updatedAdditionalData, loyaltyInfo, true);

        // Send success feedback to the user
        if(!showRenewCard) {
          displayFeedbackModal("saveProfileSuccess");
        }
        setProfileChangeState(false);
        setSavePanel("save-success");

        if(showRenewCard) {
          handleRenewCard(event);
        }

        return new Promise((resolve) => {
          resolve();
        });
      })
      .catch((err) => {
        // Send failure feedback to the user
        displayFeedbackModal("saveProfileError", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleConfirmRenewCard = (event) => {
    event.preventDefault();
  
    if(accordionConfig["starloyalty-program"]
        .isValid &&
      accordionConfig["starloyalty-program"]
        .hasChanged){
      handleUpdateStarInfo(event);
    }else{
      handleRenewCard(event);
  };
}

  const handleRenewCard = (event) => {
    event.preventDefault();

    fetch(`${config.resourceServer.agentApiUrl}/Loyalty/Card/Renew`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setRenewCardResult(true);  
        } else {
          console.log("Error", response);
          // Send failure feedback to the user
          setRenewCardResult(false);  
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setRenewCardResult(false);  
      })
      .finally(() => {
        setShowRenewCardResult(true);
      });
  }

  /****************************************************************/

  /*****************login email change****************************/

  // Email fields management
  const [newEmail, setNewEmail] = useState(null);
  const [newEmailConfirm, setNewEmailConfirm] = useState();

  // Tracks if profile email has changed and not saved
  const [hasEmailChanged, setEmailChangeState] = useState(false);

  const [isValidEmail, setValidEmail] = useState(false);
  const newEmailField = document.getElementById("ap-changeEmail");
  const newEmailConfirmField = document.getElementById("ap-confirmChangeEmail");
  const currentEmailField = document.getElementById("ap-currentEmail");

  const validateEmailFormat = () => {
    if (newEmail === null || (newEmail && newEmail.length < 1)) {
      return true;
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@'“‘”’]+(\.[^<>()[\]\\.,;:\s@'“‘”’]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(newEmail);
  };

  const validatePostalCode = () => {
    const postalRegex =
      /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ].?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;
    const postalCode = loyaltyInfo.cPostalCode;

    if (
      postalCode !== undefined &&
      postalCode !== null &&
      postalCode.replace(/\s/g, "").length !== 6
    ) {
      return false;
    }
    return postalRegex.test(postalCode);
  };

  const validatePhoneFormat = () => {
    let isValidPhone = true;
    if (newPhone && (newPhone.length < 10 || newPhone.length > 10)) {
      isValidPhone = false;
    }
    if (isValidPhone) {
      const phoneRegex = /^[0-9]+$/;
      isValidPhone = phoneRegex.test(newPhone);
    }
    return isValidPhone;
  };

  const validateSameEmail = () => {
    if (newEmailConfirm && newEmailConfirm.length < 1) {
      return true;
    }
    const sameEmailState = newEmailField.value === newEmailConfirmField.value;
    return sameEmailState;
  };

  const handleEmailChange = (event) => {
    const { name: field, value } = event.target;
    updateConfigFieldData(activePanel, field, value);

    if (field === "changeEmail") {
      setNewEmail(event.target.value);
    }

    if (field === "confirmChangeEmail") {
      setNewEmailConfirm(event.target.value);
    }

    if (newEmailField && newEmailConfirmField && currentEmailField) {
      if (
        validateEmailFormat(newEmail) &&
        validateSameEmail() &&
        currentEmailField?.value.length > 0
      ) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
    }
  };

  const handleEmailReset = () => {
    setValidEmail(false);
    setNewEmail(null);
    setNewEmailConfirm("");
    newEmailField.value = "";
    newEmailConfirmField.value = "";

    setTimeout(() => {
      updateConfigData(profileInitState, loyaltyInitState, true);
      setEmailChangeState(false);
      setProfileChangeState(false);
    }, 100);
  };

  const handleUpdateEmail = (event) => {
    /* Purpose: Handle the updating of Okta password */
    if (event) {
      event.preventDefault();
    }

    setSubmitting(true);

    // Send feedback to user
    displayFeedbackModal("saveEmail");

    return (
      fetch(`${config.resourceServer.authApiUrl}/email`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        method: "PATCH",
        body: JSON.stringify({
          EnterpriseUserReferenceKey: globalId,
          CurrentLoginEmail: currentEmail,
          NewLoginEmail: newEmail,
          ConfirmLoginEmail: newEmailConfirm,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            if (res.status !== 200) {
              return res.json().then((json) => {
                if (json.errorCode) {
                  // Catch Okta object format
                  if (json.errorCauses.length > 0) {
                    throw new Error(
                      `${json.errorCode}|${json.errorCauses[0].errorSummary}`
                    );
                  } else {
                    throw new Error(`${json.errorCode}|${json.errorSummary}`);
                  }
                } else if (typeof json === "string") {
                  // Otherwise assume string
                  throw new Error(json);
                }
              });
            }
          }
          return res.json();
        })
        // eslint-disable-next-line no-unused-vars
        .then((data) => {
          const updatedAdditionalData = {
            ...data,
            preferredLanguage: data.contactPreferences.languageCode,
            preferredRegion:
              data.contactPreferences.departureProvinces !== undefined
                ? data.contactPreferences
                : { departureProvinces: [{ code: "CA", name: "Canada" }] },
          };
          setProfileInfo(updatedAdditionalData);
          setProfileTempState(updatedAdditionalData);
          updateConfigData(updatedAdditionalData, loyaltyInfo, true);

          // Send success feedback to the user
          displayFeedbackModal("logOutModal");
          setProfileChangeState(false);
          setEmailChangeState(false);
          setSavePanel("save-success");
          handleEmailReset();
        })
        .catch((err) => {
          // Send failure feedback to the user
          displayFeedbackModal("saveEmailError", err.toString());
        })
        .finally(() => {
          setSubmitting(false);
        })
    );
  };

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/MyAccount" },
        fr: { path: "/fr/MyAccount" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***********************login email change end*****************************************/

  if (
    profileInfo &&
    loyaltyInfo &&
    loaded &&
    loyaltyLoaded &&
    !isLoyaltyInitialized &&
    !isInitialized
  ) {
    //setupGateways();

    // Initial load sequence when profile data is loaded
    setProfileInit(true);
    setProfileInfo(profileInfo);
    setGlobalId(profileInfo.globalId);
    setCurrentEmail(profileInfo.emailAddress.email);
    const selRegion =
      profileInfo.contactPreferences?.departureProvinces !== undefined
        ? profileInfo.contactPreferences?.departureProvinces.map((item) => {
            return Object.assign({}, item, {
              key: item.code,
              label: item.name,
            });
          })
        : [{ key: "CA", label: "Canada" }];
    setSelectedRegions(selRegion);
    setLoyaltyInit(true);
    setLoyaltyInfo(loyaltyInfo);
    setTimeout(() => {
      updateConfigData(profileInfo, loyaltyInfo, true);
    }, 100);
  }

  if (!(profileInfo && loyaltyInfo && loaded)) return <Loader />;

  return (
    <>
      {styles &&
        (<>
          <div>
            <Helmet>
              <title>
                {`Agent Portal - ${RCL({ searchKey: "pageMyAccount" })}`}
              </title>
            </Helmet>
            <Section 
              title={!config.isWestJetMode && RCL({ searchKey: "pageMyAccount" })} 
              titleSize="h1"
              >
              {config.isWestJetMode && (<div className={styles.pageHeading}>{RCL({ searchKey: "pageMyAccount" })} </div>)}
              {(!profileInfo || !loaded) && (
                <div className={styles.loaderDots}>
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              )}
              {profileInfo !== null && loyaltyInfo !== null && loaded && (
                <div>
                  <Accordion
                    variant="tide"
                    activeKey={activePanel}
                    className={styles.accordionContainer}
                  >
                    {/***************************Profile start*******************************************/}
                    <PanelCard
                      className={styles.accordionItem}
                      eventKey="profile-info"
                      id="profile-info"
                    >
                      <PanelCard.Toggle
                        className={`${styles.accordionToggle}
                        ${hasProfileChanged && activePanel !== "profile-info"? styles.disabled:""}`}
                        isDisabled={hasProfileChanged}
                        onToggle={(event, key) => {
                          if (
                            authState &&
                            !authState.isPending &&
                            authState.isAuthenticated
                          ) {
                            if (!hasProfileChanged) {
                              setActivePanel(key);
                              setRules(null);
                            }
                          } else {
                            // If user is not logged in then signout
                            authService.signOut({
                              postLogoutRedirectUri: `${window.location.origin}${basePath}/${routingPaths.login}`,
                            });
                          }
                        }}
                      >
                        <ToggleTemplate isActive={activePanel === "profile-info"}>
                          <span className={styles.sectionHeading}>
                            <RCL searchKey="profile" />
                            <span className={styles.sectionEdit}>
                              <Icon
                                aria-hidden="true"
                                name="edit--n"
                                className={styles.editIcon}
                              />
                              <span className={styles.editText}>
                                <RCL searchKey="edit" />
                              </span>
                            </span>
                          </span>
                        </ToggleTemplate>
                      </PanelCard.Toggle>
                      <PanelCard.Panel className={styles.accordionPanel}>
                        <React.Fragment>
                          <div className={styles.register}>
                            <span className={styles.italic}>
                              <RCL searchKey="okta-profile-required-field" />
                            </span>
                          </div>

                          <form
                            acceptCharset="UTF-8"
                            noValidate
                            onSubmit={handleUpdateProfile}
                          >
                            <div className={styles.heading}>
                              <RCL searchKey="okta-profile-display-name" />
                            </div>
                            <Row cols={2} className={styles.formGroup}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-firstName">
                                    <span
                                       className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="firstName" />{" "}
                                    </span>
                                    <input
                                      value={profileInfo.firstName}
                                      type="text"
                                      className="form-control"
                                      id="ap-firstName"
                                      name="firstName"
                                      maxLength="50"
                                      onChange={handleChange}
                                      onBlur={handleChange}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "firstName",
                                    "name"
                                  )}
                                </div>
                              </Cell>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-lastName">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="lastName" />
                                    </span>
                                    <input
                                      value={profileInfo.lastName}
                                      type="text"
                                      className="form-control"
                                      id="ap-lastName"
                                      name="lastName"
                                      maxLength="50"
                                      onChange={handleChange}
                                      onBlur={handleChange}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "lastName",
                                    "name"
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-email">
                                    <span className={styles.inputContainer__label}>
                                      <RCL searchKey="email" />
                                    </span>
                                    <input
                                      value={profileInfo.emailAddress.email}
                                      type="text"
                                      className="form-control"
                                      id="ap-email"
                                      name="email"
                                      maxLength="50"
                                      onChange={handleChange}
                                      onBlur={handleChange}
                                      disabled={submitting}
                                      readOnly
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "email",
                                    "name"
                                  )}
                                </div>
                              </Cell>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <fieldset className={styles.svgRadio}>
                                    <legend
                                      className={`${styles.heading} ${styles.required}`}
                                    >
                                      <RCL searchKey="languagePreference" />
                                    </legend>
                                    <div className={styles.radioGroup}>
                                      <input
                                        checked={
                                          profileInfo.preferredLanguage.substring(
                                            0,
                                            2
                                          ).toLowerCase() === "en"
                                        }
                                        name="preferredLanguage"
                                        defaultValue="en"
                                        id="ap-english"
                                        type="radio"
                                        required={true}
                                        onChange={handleChange}
                                        disabled={submitting}
                                      />
                                      <label htmlFor="ap-english">
                                        <RCL searchKey="english" />
                                      </label>
                                    </div>
                                    <div className={styles.radioGroup}>
                                      <input
                                        checked={
                                          profileInfo.preferredLanguage.substring(
                                            0,
                                            2
                                          ).toLowerCase() === "fr"
                                        }
                                        name="preferredLanguage"
                                        defaultValue="fr"
                                        id="ap-french"
                                        type="radio"
                                        required={true}
                                        onChange={handleChange}
                                        disabled={submitting}
                                      />
                                      <label htmlFor="ap-french">
                                        <RCL searchKey="french" />
                                      </label>
                                    </div>
                                  </fieldset>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "preferredLanguage",
                                    "radio"
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-agencyName">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="agencyName" />{" "}
                                    </span>
                                    <input
                                      value={profileInfo.agencyName}
                                      type="text"
                                      className="form-control"
                                      id="ap-agencyName"
                                      name="agencyName"
                                      maxLength="50"
                                      onChange={handleChange}
                                      onBlur={handleChange}
                                      disabled={submitting}
                                      readOnly
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "agencyName",
                                    "name"
                                  )}
                                </div>
                              </Cell>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-agencyNumber">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="agencyNumber" />
                                    </span>
                                    <input
                                      value={profileInfo.agencyNumber}
                                      type="text"
                                      className="form-control"
                                      id="ap-agencyNumber"
                                      name="agencyNumber"
                                      maxLength="10"
                                      onChange={handleAgencyChange}
                                      onBlur={handleAgencyChange}
                                      onKeyPress={(event) => {
                                        allowNumbers(
                                          event.nativeEvent,
                                          event.target.value.length
                                        );
                                      }}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "agencyNumber",
                                    "name"
                                  )}
                                  {!validAgencyPhone && (
                                    <div className={styles.validationErrorMessage}>
                                      <RCL searchKey="ERR_AgencyPhoneRequired" />
                                    </div>
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-preferredRegion">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="PreferredRegion" />
                                    </span>
                                  </label>
                                  <Select
                                    style={{ minWidth: 365, minHeight: 40 }}
                                    id="ap-preferredRegion"
                                    name="preferredRegion"
                                    mode={"multiple"}
                                    filterOption={(inputValue, option) =>
                                      option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    labelInValue={true}
                                    onChange={handleMultipleSelect}
                                    value={selectedRegions}
                                  >
                                    {regions &&
                                      regions.map((item) => {
                                        return (
                                          <SelectOption
                                            key={item["cProvinceCode"]}
                                            value={item["cProvinceCode"]}
                                          >
                                            {item["cProvinceName"]}
                                          </SelectOption>
                                        );
                                      })}
                                  </Select>
                                  {displayValidationMessage(
                                    "profile-info",
                                    "preferredRegion",
                                    "multipleSelect"
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell className={styles.noMarginBottom}>
                                <div className={styles.sectionButtons}>
                                  <Button
                                    className={styles.sectionButtons__btn}
                                    type="submit"
                                    onClick={null}
                                    buttonType="primary"
                                    disabled={
                                      submitting ||
                                      (!accordionConfig["profile-info"].isValid &&
                                        accordionConfig["profile-info"]
                                          .hasChanged) ||
                                      (accordionConfig["profile-info"].isValid &&
                                        !accordionConfig["profile-info"]
                                          .hasChanged) ||
                                      (!accordionConfig["profile-info"].isValid &&
                                        !accordionConfig["profile-info"]
                                          .hasChanged) ||
                                      (accordionConfig["profile-info"].isValid &&
                                        accordionConfig["profile-info"]
                                          .hasChanged &&
                                        !validAgencyPhone)
                                    }
                                  >
                                    {dictionary["okta-profile-panel-save"]}
                                  </Button>
                                  {
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="button"
                                      outline={true}
                                      onClick={() => resetProfileState()}
                                      disabled={
                                        submitting ||
                                        !accordionConfig["profile-info"].hasChanged
                                      }
                                    >
                                      {dictionary["okta-profile-panel-cancel"]}
                                    </Button>
                                  }
                                </div>
                              </Cell>
                            </Row>
                          </form>
                        </React.Fragment>
                      </PanelCard.Panel>
                    </PanelCard>
                    {/***************************Profile end*******************************************/}

                    {/***************************Loyalty Program start*********************************/}
                    <PanelCard
                      className={styles.accordionItem}
                      eventKey="loyalty-program"
                      id="loyalty-program"
                    >
                      <PanelCard.Toggle
                        className={`${styles.accordionToggle}
                        ${hasProfileChanged && activePanel !== "loyalty-program"? styles.disabled:""}`}
                        isDisabled={hasProfileChanged}
                        onToggle={(event, key) => {
                          if (
                            authState &&
                            !authState.isPending &&
                            authState.isAuthenticated
                          ) {
                            if (!hasProfileChanged) {
                              setActivePanel(key);
                              setRules(null);
                            }
                          } else {
                            // If user is not logged in then signout
                            authService.signOut({
                              postLogoutRedirectUri: `${window.location.origin}${basePath}/${routingPaths.login}`,
                            });
                          }
                        }}
                      >
                        <ToggleTemplate
                          isActive={activePanel === "loyalty-program"}
                        >
                          <span className={styles.sectionHeading}>
                            <RCL searchKey="Riuloyaltyprogram" />
                            <span className={styles.sectionEdit}>
                              <Icon
                                aria-hidden="true"
                                name="edit--n"
                                className={styles.editIcon}
                              />
                              <span className={styles.editText}>
                                <RCL searchKey="edit" />
                              </span>
                            </span>
                          </span>
                        </ToggleTemplate>
                      </PanelCard.Toggle>
                      <PanelCard.Panel className={styles.accordionPanel}>
                        <React.Fragment>
                          <form
                            acceptCharset="UTF-8"
                            noValidate
                            onSubmit={handleUpdateRiuInfo}
                          >
                            <Row cols={2} className={styles.formGroup}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-cPartnerCard">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="riuPartnerClubNumber" />{" "}
                                    </span>
                                    <input
                                      value={loyaltyInfo.cPartnerCard}
                                      type="text"
                                      className="form-control"
                                      id="ap-cPartnerCard"
                                      name="cPartnerCard"
                                      maxLength="4"
                                      onChange={handleStarChange}
                                      onBlur={handleStarChange}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "loyalty-program",
                                    "cPartnerCard",
                                    "name"
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell className={styles.noMarginBottom}>
                                <div className={styles.sectionButtons}>
                                  <Button
                                    className={styles.sectionButtons__btn}
                                    type="submit"
                                    onClick={null}
                                    buttonType="primary"
                                    disabled={
                                      submitting ||
                                      (!accordionConfig["loyalty-program"]
                                        .isValid &&
                                        accordionConfig["loyalty-program"]
                                          .hasChanged) ||
                                      (accordionConfig["loyalty-program"].isValid &&
                                        !accordionConfig["loyalty-program"]
                                          .hasChanged) ||
                                      (!accordionConfig["loyalty-program"]
                                        .isValid &&
                                        !accordionConfig["loyalty-program"]
                                          .hasChanged)
                                    }
                                  >
                                    {dictionary["save"]}
                                  </Button>
                                  {
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="button"
                                      outline={true}
                                      onClick={() => resetRIUInfoState()}
                                      disabled={
                                        submitting ||
                                        !accordionConfig["loyalty-program"]
                                          .hasChanged
                                      }
                                    >
                                      {dictionary["cancel"]}
                                    </Button>
                                  }
                                </div>
                              </Cell>
                            </Row>
                          </form>
                        </React.Fragment>
                      </PanelCard.Panel>
                    </PanelCard>
                    {/***************************Loyalty program end***********************************************/}

                    {/***************************Start Star Loyalty program****************************************/}
                    <PanelCard
                      className={styles.accordionItem}
                      eventKey="starloyalty-program"
                      id="starloyalty-program"
                    >
                      <PanelCard.Toggle
                        className={`${styles.accordionToggle}
                        ${hasProfileChanged && activePanel !==  "starloyalty-program"? styles.disabled:""}`}
                        isDisabled={hasProfileChanged}
                        onToggle={(event, key) => {
                          if (
                            authState &&
                            !authState.isPending &&
                            authState.isAuthenticated
                          ) {
                            if (!hasProfileChanged) {
                              setActivePanel(key);
                              setRules(null);
                            }
                          } else {
                            // If user is not logged in then signout
                            authService.signOut({
                              postLogoutRedirectUri: `${window.location.origin}${basePath}/${routingPaths.login}`,
                            });
                          }
                        }}
                      >
                        <ToggleTemplate
                          isActive={activePanel === "starloyalty-program"}
                        >
                          <span className={styles.sectionHeading}>
                            <RCL searchKey="sunwingStarLoyaltyProgram" />
                            <span className={styles.sectionEdit}>
                              <Icon
                                aria-hidden="true"
                                name="edit--n"
                                className={styles.editIcon}
                              />
                              <span className={styles.editText}>
                                <RCL searchKey="edit" />
                              </span>
                            </span>
                          </span>
                        </ToggleTemplate>
                      </PanelCard.Toggle>
                      <PanelCard.Panel className={styles.accordionPanel}>
                        <React.Fragment>
                          {!loyaltyCardRegistered ? (
                            <Section padding="bottom">
                              <div
                                className={starclass(
                                  starcardStyles.starCard,
                                  starcardStyles.starCardUnregistered
                                )}
                              >
                                <div className={starcardStyles.starCardHeading}>
                                  <div className={starcardStyles.starCardImage}>
                                    <Img
                                      src="https://assets.sunwingtravelgroup.com/image/upload/v1635177525/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/STAR-Card.jpg"
                                      alt="Star Card"
                                    />
                                  </div>
                                  <Heading as="h4">
                                    <RCL searchKey="yourStarCard" />
                                  </Heading>
                                </div>
                                <div
                                  className={
                                    starcardStyles.starCardUnregisteredDesc
                                  }
                                >
                                  <p>
                                    <RCL searchKey="STAR_NoCard" />
                                  </p>
                                </div>
                                {isOpen && loyaltyInfo && provinceList  && <StarCardModal isOpen={isOpen} provinceList={provinceList} starCardData={loyaltyInfo} setStarCardData={setLoyaltyInfo} setStarCardInfo={setStarCardInfo} setLoyaltyCardRegistered={setLoyaltyCardRegistered} locale={locale} />}
                                <div
                                  className={
                                    (starcardStyles.starCardCtas,
                                    starcardStyles.starCardUnregisteredCtas)
                                  }
                                >
                                  <Button
                                    className={starcardStyles.registerButton}
                                    onClick={handleShowRegistrationModal}
                                  >
                                    {dictionary["registerToStar"]}
                                  </Button>
                                  <Link to="/">
                                    {dictionary["STAR_whatprogram"]}{" "}
                                    <Icon name="arrow-right" />
                                  </Link>
                                </div>
                              </div>
                            </Section>
                          ) : (
                            <form
                              acceptCharset="UTF-8"
                              noValidate
                              onSubmit={handleUpdateStarInfo}
                            >
                              <Row cols={2} className={styles.formGroup}>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-starCardNumber">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="starCardNumber" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cPaidMobile}
                                        placeholder={dictionary["processing"]}
                                        type="text"
                                        className="form-control"
                                        id="ap-starCardNumber"
                                        name="cPaidMobile"
                                        maxLength="16"
                                        onChange={handleStarChange}
                                        disabled={submitting}
                                        readOnly
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cPaidMobile",
                                      "name"
                                    )}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <Img
                                      src={dictionary["star-proxy-image"]}
                                      alt="Star Card"
                                    />
                                  </div>
                                </Cell>
                              </Row>
                              <Row cols={2} className={styles.formGroup}>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-cContactPhone">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="contactPhoneNumber" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cContactPhone}
                                        type="text"
                                        className="form-control"
                                        id="ap-cContactPhone"
                                        name="cContactPhone"
                                        maxLength="30"
                                        onChange={handleStarChange}
                                        onBlur={handleStarChange}
                                        disabled={submitting}
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cContactPhone",
                                      "name"
                                    )}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-fax">
                                      <span 
                                        className={styles.inputContainer__label}
                                      >
                                        <RCL searchKey="fax" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cFax?loyaltyInfo.cFax : " "}
                                        type="text"
                                        className="form-control"
                                        id="ap-fax"
                                        name="cFax"
                                        maxLength="20"
                                        onChange={handleStarChange}
                                        onBlur={handleStarChange}
                                        disabled={submitting}
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cFax",
                                      "name"
                                    )}
                                  </div>
                                </Cell>
                              </Row>
                              <Row cols={2} className={styles.formGroup}>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-contactAddress">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="Address_STAR" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cAddress1}
                                        type="text"
                                        className="form-control"
                                        id="ap-contactAddress"
                                        name="cAddress1"
                                        maxLength="50"
                                        onChange={handleStarChange}
                                        onBlur={handleStarChange}
                                        onFocus={handleStarAddress1Focus}
                                        disabled={submitting}
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cAddress1",
                                      "name"
                                    )}
                                    {showAddress1Example && (<div className={styles.addressExample}><RCL searchKey="please-follow-the-correct-mailing-address-format-as-per-displayed-example" /></div>)}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-city">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="city" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cCity}
                                        type="text"
                                        className="form-control"
                                        id="ap-city"
                                        name="cCity"
                                        maxLength="50"
                                        onChange={handleStarChange}
                                        onBlur={handleStarChange}
                                        disabled={submitting}
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cCity",
                                      "name"
                                    )}
                                  </div>
                                </Cell>
                              </Row>
                              <Row cols={2} className={styles.formGroup}>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-province">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="province" />{" "}
                                      </span>
                                      <div className={styles.inputWrapper}>
                                        <select
                                          name="cProvince"
                                          id="ap-province"
                                          required={true}
                                          onChange={handleStarChange}
                                          disabled={submitting}
                                          value={loyaltyInfo.cProvince}
                                        >
                                          <option value="">
                                            {RCL({
                                              searchKey: "select",
                                            })}
                                          </option>
                                          {provinceList &&
                                            provinceList.map((prv) => (
                                              <option
                                                key={prv.cProvince}
                                                value={prv.cProvince}
                                              >
                                                {prv.cProvinceName}
                                              </option>
                                            ))}
                                        </select>
                                        <Icon
                                          aria-hidden="true"
                                          name="arrow-down"
                                        />
                                      </div>
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cProvince",
                                      "select"
                                    )}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className={styles.inputContainer}>
                                    <label htmlFor="ap-postalCode">
                                      <span
                                        className={`${styles.inputContainer__label} ${styles.required}` }
                                      >
                                        <RCL searchKey="postalCode" />{" "}
                                      </span>
                                      <input
                                        value={loyaltyInfo.cPostalCode}
                                        type="text"
                                        className="form-control"
                                        id="ap-postalCode"
                                        name="cPostalCode"
                                        maxLength="10"
                                        onChange={handleStarChange}
                                        onBlur={handleStarChange}
                                        disabled={submitting}
                                      />
                                    </label>
                                    {displayValidationMessage(
                                      "starloyalty-program",
                                      "cPostalCode",
                                      "postal"
                                    )}
                                    {!validatePostalCode() && (
                                      <div
                                        className={styles.validationErrorMessage}
                                      >
                                        <RCL searchKey="ERR_InvalidPostalCode" />
                                      </div>
                                    )}
                                  </div>
                                </Cell>
                              </Row>
                              <Row cols={2}>
                                <Cell className={styles.noMarginBottom}>
                                  <div className={styles.sectionButtons}>
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="submit"
                                      onClick={null}
                                      buttonType="primary"
                                      disabled={
                                        submitting ||
                                        (!accordionConfig["starloyalty-program"]
                                          .isValid &&
                                          accordionConfig["starloyalty-program"]
                                            .hasChanged) ||
                                        (accordionConfig["starloyalty-program"]
                                          .isValid &&
                                          !accordionConfig["starloyalty-program"]
                                            .hasChanged) ||
                                        (!accordionConfig["starloyalty-program"]
                                          .isValid &&
                                          !accordionConfig["starloyalty-program"]
                                            .hasChanged) ||
                                        showRenewCard
                                      }
                                    >
                                      {dictionary["save"]}
                                    </Button>
                                    {
                                      <Button
                                        className={styles.sectionButtons__btn}
                                        type="button"
                                        outline={true}
                                        onClick={() => resetStarInfoState()}
                                        disabled={
                                          submitting ||
                                          !accordionConfig["starloyalty-program"]
                                            .hasChanged
                                        }
                                      >
                                        {dictionary["cancel"]}
                                      </Button>
                                    }
                                    
                                  </div>
                                </Cell>
                                { showRenewCard && (<Cell className={styles.noMarginBottom}>
                                  <div className={styles.sectionButtons}>
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="button"
                                      onClick={handleConfirmRenewCard}
                                      buttonType="primary"
                                      disabled={
                                        !accordionConfig["starloyalty-program"]
                                          .isValid &&
                                          accordionConfig["starloyalty-program"]
                                            .hasChanged
                                      }
                                    >
                                      {dictionary["confirm-renewal"]}
                                    </Button>
                                    {
                                      <Button
                                        className={styles.sectionButtons__btn}
                                        type="button"
                                        outline={true}
                                        onClick={() => { window.location.href = window.location.origin + `/${locale==='en'? 'en' : 'fr'}/my-loyalty-program?brand=${config.isWestJetMode ? "vwq" : "swg"}`}}
                                       >
                                         {dictionary["cancel-renewal"]}
                                      </Button>
                                    }
                                    
                                  </div>
                                </Cell>)}
                              </Row>
                            </form>
                          )}
                        </React.Fragment>
                      </PanelCard.Panel>
                    </PanelCard>
                    {/***************************End Star Loyalty program****************************************/}

                    {/**************************Change password start*********************************************/}
                    <PanelCard
                      className={styles.accordionItem}
                      eventKey="email-password"
                      id="email-password"
                    >
                      <PanelCard.Toggle
                        className={`${styles.accordionToggle}
                        ${hasProfileChanged && activePanel !== "email-password"? styles.disabled:""}`}
                        isDisabled={hasProfileChanged}
                        onToggle={(event, key) => {
                          if (
                            authState &&
                            !authState.isPending &&
                            authState.isAuthenticated
                          ) {
                            if (!hasProfileChanged) {
                              setActivePanel(key);

                              // Retrieve validation rules if not available
                              if (
                                activePanel !== key &&
                                (getRules === null || !getRules)
                              ) {
                                loadPasswordValidationRules();
                              }
                            }
                          } else {
                            // If user is not logged in then signout
                            authService.signOut({
                              postLogoutRedirectUri: `${window.location.origin}${basePath}/${routingPaths.login}`,
                            });
                          }
                        }}
                      >
                        <ToggleTemplate isActive={activePanel === "email-password"}>
                          <span className={styles.sectionHeading}>
                            <RCL searchKey="changePassword" />
                            <span className={styles.sectionEdit}>
                              <Icon
                                aria-hidden="true"
                                name="edit--n"
                                className={styles.editIcon}
                              />
                              <span className={styles.editText}>
                                <RCL searchKey="edit" />
                              </span>
                            </span>
                          </span>
                        </ToggleTemplate>
                      </PanelCard.Toggle>
                      <PanelCard.Panel className={styles.accordionPanel}>
                        <React.Fragment>
                          <div className={styles.register}>
                            {getRules === false && (
                              <span className={styles.instructions}>
                                <RCL searchKey="okta-profile-error-your-profile-not-loaded" />
                              </span>
                            )}
                          </div>

                          <form
                            acceptCharset="UTF-8"
                            noValidate
                            onSubmit={handleChangePassword}
                            style={{ opacity: getRules === false ? 0.8 : 1 }}
                          >
                            <Row cols={3}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-email">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="email" />
                                    </span>
                                    <input
                                      defaultValue={profileInfo.emailAddress.email}
                                      type="text"
                                      className="form-control"
                                      id="ap-email"
                                      name="email"
                                      required
                                      onChange={handleChange}
                                      disabled={submitting || getRules === false}
                                      readOnly
                                    />
                                  </label>
                                </div>
                              </Cell>
                            </Row>

                            <Row cols={3}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-oldPassword">
                                    <span className={styles.inputContainer__label}>
                                      <RCL searchKey="currentPassword" />
                                    </span>
                                    <div className={styles.passwordInput}>
                                      <input
                                        type="password"
                                        className="form-control"
                                        id="ap-oldPassword"
                                        name="oldPassword"
                                        onCopy={(event) => event.preventDefault()}
                                        onPaste={(event) => event.preventDefault()}
                                        onChange={(event) => {
                                          handlePasswordValidation();
                                          setOldPassword(event.target.value);
                                        }}
                                        disabled={submitting || getRules === false}
                                      />
                                      <Button
                                        type="button"
                                        noStyle={true}
                                        className={styles.togglePassword}
                                        label={dictionary["okta-toggle-visibility"]}
                                        disabled={getRules === false}
                                        onClick={(event) => {
                                          togglePassword(
                                            event,
                                            document.getElementById(
                                              "ap-oldPassword"
                                            )
                                          );
                                        }}
                                      >
                                        <Icon
                                          aria-hidden="true"
                                          name="gallery"
                                          className={styles.showPassword}
                                        />
                                        <Icon
                                          aria-hidden="true"
                                          name="eye-slash"
                                          className={styles.hidePassword}
                                        />
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              </Cell>
                            </Row>

                            <Row cols={3}>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-newPassword">
                                    <span className={styles.inputContainer__label}>
                                      <RCL searchKey="newPassword" />
                                    </span>
                                    <div className={styles.passwordInput}>
                                      <input
                                        type="password"
                                        className="form-control"
                                        id="ap-newPassword"
                                        name="newPassword"
                                        onCopy={(event) => event.preventDefault()}
                                        onPaste={(event) => event.preventDefault()}
                                        onChange={(event) => {
                                          handlePasswordValidation();
                                          setNewPassword(event.target.value);
                                        }}
                                        disabled={submitting || getRules === false}
                                      />
                                      <Button
                                        type="button"
                                        noStyle={true}
                                        className={styles.togglePassword}
                                        label={dictionary["okta-toggle-visibility"]}
                                        disabled={getRules === false}
                                        onClick={(event) => {
                                          togglePassword(
                                            event,
                                            document.getElementById(
                                              "ap-newPassword"
                                            )
                                          );
                                        }}
                                      >
                                        <Icon
                                          aria-hidden="true"
                                          name="gallery"
                                          className={styles.showPassword}
                                        />
                                        <Icon
                                          aria-hidden="true"
                                          name="eye-slash"
                                          className={styles.hidePassword}
                                        />
                                      </Button>
                                    </div>
                                  </label>
                                  {newPasswordField?.value && oldPasswordField && (
                                    <div
                                      className={styles.customValidationMessages}
                                    >
                                      <ul>
                                        <li
                                          className={`${styles.icon} ${styles.charsError} ${validateChars()? styles.pass:""}` }
                                        >
                                          <span>
                                            {dictionary[
                                              "okta-widget-chars"
                                            ].replace("{0}", getCharLength)}
                                          </span>
                                        </li>
                                        {getNumberChars && (
                                          <li
                                            className={`${styles.icon} ${styles.numberError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-password-validation-number" />
                                            </span>
                                          </li>
                                        )}
                                        {getLowerCaseChars && (
                                          <li                                         
                                            className={`${styles.icon} ${styles.lowercaseError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-lowercase" />
                                            </span>
                                          </li>
                                        )}
                                        {getUpperCaseChars && (
                                          <li
                                            className={`${styles.icon} ${styles.uppercaseError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-uppercase" />
                                            </span>
                                          </li>
                                        )}
                                        {isUserNameChecked && (
                                          <li
                                            className={`${styles.icon} ${styles.charsError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-username" />
                                            </span>
                                          </li>
                                        )}
                                        <li
                                          className={`${styles.icon} ${styles.passwordError} ${validateChars()? styles.pass:""}` }
                                        >
                                          <span>
                                            <RCL searchKey="okta-profile-validation-old-password" />
                                          </span>
                                        </li>
                                        {getSymbolChars && (
                                          <li
                                            className={`${styles.icon} ${styles.specialError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-special-characters" />{" "}
                                              {getSymbolChars
                                                .substring(
                                                  1,
                                                  getSymbolChars.length - 2
                                                )
                                                .replace(/\\\[/g, "[")
                                                .replace(/\\\]/g, "]")
                                                .replace(/\\\//g, "/")
                                                .replace(/\\\\/g, "\\")}
                                            </span>
                                          </li>
                                        )}
                                        {isUserNameChecked && (
                                          <li
                                            className={`${styles.icon} ${styles.firstnameError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-first-name-passport" />
                                            </span>
                                          </li>
                                        )}
                                        {isUserNameChecked && (
                                          <li
                                            className={`${styles.icon} ${styles.lastnameError} ${validateChars()? styles.pass:""}` }
                                          >
                                            <span>
                                              <RCL searchKey="okta-profile-validation-last-name-passport" />
                                            </span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Cell>
                              <Cell>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-newPasswordConfirm">
                                    <span className={styles.inputContainer__label}>
                                      <RCL searchKey="confirmNewPassword" />
                                    </span>
                                    <div className={styles.passwordInput}>
                                      <input
                                        type="password"
                                        className="form-control"
                                        id="ap-newPasswordConfirm"
                                        name="newPasswordConfirm"
                                        onCopy={(event) => event.preventDefault()}
                                        onPaste={(event) => event.preventDefault()}
                                        onChange={(event) => {
                                          handlePasswordValidation();
                                          setNewPasswordConfirm(event.target.value);
                                        }}
                                        disabled={submitting || getRules === false}
                                      />
                                      <Button
                                        type="button"
                                        noStyle={true}
                                        className={styles.togglePassword}
                                        label={dictionary["okta-toggle-visibility"]}
                                        disabled={getRules === false}
                                        onClick={(event) => {
                                          togglePassword(
                                            event,
                                            document.getElementById(
                                              "ap-newPasswordConfirm"
                                            )
                                          );
                                        }}
                                      >
                                        <Icon
                                          aria-hidden="true"
                                          name="gallery"
                                          className={styles.showPassword}
                                        />
                                        <Icon
                                          aria-hidden="true"
                                          name="eye-slash"
                                          className={styles.hidePassword}
                                        />
                                      </Button>
                                    </div>
                                  </label>
                                  {newPasswordField &&
                                    newPasswordConfirmField &&
                                    !validateSamePassword() && (
                                      <div
                                        className={styles.validationErrorMessage}
                                      >
                                        <RCL searchKey="okta-profile-validation-confirm-password-do-not-match" />
                                      </div>
                                    )}
                                </div>
                              </Cell>
                            </Row>

                            <Row cols={3}>
                              <Cell className={styles.noMarginBottom}>
                                <div className={styles.sectionButtons}>
                                  <Button
                                    className={styles.sectionButtons__btn}
                                    type="submit"
                                    onClick={null}
                                    disabled={submitting || !isPasswordValid}
                                    buttonType="primary"
                                  >
                                    {dictionary["okta-profile-panel-save"]}
                                  </Button>
                                  {
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="button"
                                      outline={true}
                                      onClick={() => handlePasswordReset()}
                                      disabled={submitting || !isPasswordValid}
                                    >
                                      {dictionary["okta-profile-panel-cancel"]}
                                    </Button>
                                  }
                                </div>
                              </Cell>
                            </Row>
                          </form>
                        </React.Fragment>
                      </PanelCard.Panel>
                    </PanelCard>
                    {/***************************End change password*********************************/}

                    {/***************************Start change login email****************************/}
                    <PanelCard
                      className={styles.accordionItem}
                      eventKey="change-login-email"
                      id="change-login-email"
                    >
                      <PanelCard.Toggle
                        className={`${styles.accordionToggle}
                        ${hasProfileChanged && activePanel !== "change-login-email"? styles.disabled:""}`}
                        isDisabled={hasProfileChanged}
                        onToggle={(event, key) => {
                          if (
                            authState &&
                            !authState.isPending &&
                            authState.isAuthenticated
                          ) {
                            if (!hasProfileChanged) {
                              setActivePanel(key);
                              setRules(null);
                            }
                          } else {
                            // If user is not logged in then signout
                            authService.signOut({
                              postLogoutRedirectUri: `${window.location.origin}${basePath}/${routingPaths.login}`,
                            });
                          }
                        }}
                      >
                        <ToggleTemplate
                          isActive={activePanel === "change-login-email"}
                        >
                          <span className={styles.sectionHeading}>
                            <RCL searchKey="changeLoginEmail" />
                            <span className={styles.sectionEdit}>
                              <Icon
                                aria-hidden="true"
                                name="edit--n"
                                className={styles.editIcon}
                              />
                              <span className={styles.editText}>
                                <RCL searchKey="edit" />
                              </span>
                            </span>
                          </span>
                        </ToggleTemplate>
                      </PanelCard.Toggle>
                      <PanelCard.Panel className={styles.accordionPanel}>
                        <React.Fragment>
                          <form
                            acceptCharset="UTF-8"
                            noValidate
                            onSubmit={handleUpdateEmail}
                          >
                            <Row cols={2} className={styles.formGroup}>
                              <Cell>
                                <div className={styles.subheading}>
                                  <RCL searchKey="email" />
                                </div>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-currentEmail">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="usedToSignIn" />{" "}
                                    </span>
                                    <input
                                      value={profileInfo.emailAddress.email}
                                      type="text"
                                      className="form-control"
                                      id="ap-currentEmail"
                                      name="currentEmail"
                                      maxLength="50"
                                      disabled={submitting}
                                      readOnly
                                    />
                                  </label>
                                  {displayValidationMessage(
                                    "change-login-email",
                                    "currentEmail",
                                    "email"
                                  )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2} className={styles.formGroup}>
                              <Cell>
                                <div className={styles.subheading}>
                                  <RCL searchKey="changeEmail" />
                                </div>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-changeEmail">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="usedToSignIn" />{" "}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="ap-changeEmail"
                                      name="changeEmail"
                                      maxLength="50"
                                      onCopy={(event) => event.preventDefault()}
                                      onPaste={(event) => event.preventDefault()}
                                      onChange={handleEmailChange}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {newEmailField && !validateEmailFormat() && (
                                    <div className={styles.validationErrorMessage}>
                                      <RCL searchKey="ERR_InvalidEmailFormat" />
                                    </div>
                                  )}
                                </div>
                              </Cell>
                              <Cell>
                                <div className={styles.subheading}>
                                  <RCL searchKey="confirmChangeEmail" />
                                </div>
                                <div className={styles.inputContainer}>
                                  <label htmlFor="ap-confirmChangeEmail">
                                    <span
                                      className={`${styles.inputContainer__label} ${styles.required}` }
                                    >
                                      <RCL searchKey="usedToSignIn" />{" "}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="ap-confirmChangeEmail"
                                      name="confirmChangeEmail"
                                      maxLength="50"
                                      onCopy={(event) => event.preventDefault()}
                                      onPaste={(event) => event.preventDefault()}
                                      onChange={handleEmailChange}
                                      disabled={submitting}
                                    />
                                  </label>
                                  {newEmailField &&
                                    newEmailConfirmField &&
                                    !validateSameEmail() && (
                                      <div
                                        className={styles.validationErrorMessage}
                                      >
                                        <RCL searchKey="ERR_EmailMismatch" />
                                      </div>
                                    )}
                                </div>
                              </Cell>
                            </Row>
                            <Row cols={2}>
                              <Cell className={styles.noMarginBottom}>
                                <div className={styles.sectionButtons}>
                                  <Button
                                    className={styles.sectionButtons__btn}
                                    type="submit"
                                    onClick={null}
                                    buttonType="primary"
                                    disabled={submitting || !isValidEmail}
                                  >
                                    {dictionary["save"]}
                                  </Button>
                                  {
                                    <Button
                                      className={styles.sectionButtons__btn}
                                      type="button"
                                      outline={true}
                                      onClick={() => handleEmailReset()}
                                      disabled={
                                        submitting ||
                                        !accordionConfig["change-login-email"]
                                          .hasChanged
                                      }
                                    >
                                      {dictionary["cancel"]}
                                    </Button>
                                  }
                                </div>
                              </Cell>
                            </Row>
                          </form>
                        </React.Fragment>
                      </PanelCard.Panel>
                    </PanelCard>
                    {/***************************End change login email******************************/}
                  </Accordion>
                </div>
              )}
            </Section>
          </div>

          {isFeedbackModalOpen && (
            <FocusTrap
              active={isFocusTrapActive}
              focusTrapOptions={{
                clickOutsideDeactivates: false,
                escapeDeactivates: false,
              }}
            >
              <div className={modal("feedbackModal")} tabIndex="-1">
                <div className={modal("modalDialog")}>
                  <div className={modal("modalContent")}>
                    <div className={modal("modalHeader")}>
                      {modalClosable && (
                        <button
                          type="button"
                          className={modal("close")}
                          aria-label={dictionary["modal-close"]}
                          onClick={closeFeedbackModal}
                        >
                          <span aria-hidden="false">
                            <Icon
                              name="close--n"
                              aria-hidden="true"
                              className={modal("closeIcon")}
                            />
                          </span>
                        </button>
                      )}
                      <span className={modal("modalTitle")}>
                        {dictionary[modalHeaderRCLKey]}
                      </span>
                    </div>

                    <div className={modal("modalBody")}>
                      {modalBodyRCLKey && dictionary[modalBodyRCLKey]}
                      {modalShowLoader && <Loader />}
                    </div>
                    {(modalClosable || whichFeedbackModal === "logOutModal") && (
                      <div className={modal("modalFooter")}>
                        {whichFeedbackModal === "logOutModal" ? (
                          <div className={modal("saveGroup")}>
                            <Button
                              outline={true}
                              onClick={logout}
                              className={modal("saveButton")}
                            >
                              {dictionary["okta-profile-panel-logout"]}
                            </Button>
                          </div>
                        ) : (
                          <Button onClick={closeFeedbackModal}>
                            {dictionary["modal-close"]}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </FocusTrap>
          )}
          <Modal
            visible={showRenewCardResult}
            onCancel={() => {setShowRenewCardResult(false)}}
            footer={false}
            bodyStyle={{ color: renewCardResult ? "green" : "red" }}
          >
            <div className={"alert alert-danger"} role="alert">
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  {renewCardResult ? (
                    <RCL searchKey="OK_StarCardRenewed" />
                  ) : (
                    <RCL searchKey="ERR_RequestNotCompleted" />
                  )}
                </Col>
                <Col className="gutter-row" span={12}>
                  <Image
                    src="https://images.ctfassets.net/l4g28vcnjqdr/5cwvy5thUsIM0O2EosAMoo/7d1bd61c21bd4b4dff711e06a75d2c9a/StarCard.JPG"
                    width={200}
                    height={140}
                    preview={false}
                  />
                </Col>
              </Row>
            </div>
          </Modal>
       </>)
      }
    </>
  );
};

export default MyAccount;
